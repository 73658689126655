.BackgroundGifLayer {
  height: calc(100% - 4rem);
  width: 100%;
  position: absolute;
  background: url("../../../public/welcome-bg.GIF");
  background-repeat: repeat;
  z-index: -1;
  overflow-y: hidden;
}

.rose {
  height: 7rem;
  top: 15%;
  left: 10%;
  position: absolute;
}

.dragon {
  height: 30vh;
  top: 5%;
  left: 30%;
  position: absolute;
}

.chandelier {
  height: 12rem;
  top: 50%;
  left: 25%;
  position: absolute;
}

.chandelier2 {
  height: 12rem;
  top: 50%;
  right: 25%;
  position: absolute;
}

.lightning1 {
  height: 20rem;
  top: 0;
  right: 50%;
  position: absolute;
}

.lightning2 {
  height: 13rem;
  top: 0;
  right: 30%;
  position: absolute;
}

.castle {
  top: 10%;
  right: 40%;
  position: absolute;
}

.death {
  height: 14rem;
  top: 30%;
  right: 20%;
  position: absolute;
}

.death2 {
  height: 30rem;
  bottom: -10%;
  right: 10%;
  position: absolute;
}

.goofy {
  height: 10rem;
  bottom: 0;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 1600px) {
  .death {
    right: 5%;
  }

  .death2 {
    right: 2%;
  }
}

@media only screen and (max-width: 1300px) {
  .chandelier {
    left: 12%;
  }

  .chandelier2 {
    right: 12%;
  }

  .castle {
    right: 10%;
  }

  .dragon {
    left: 40%;
  }
}

@media only screen and (max-width: 800px) {
  .rose {
    height: 4rem;
    top: 80%;
    left: 20%;
  }

  .dragon {
    height: 10rem;
    top: 10%;
    left: 30%;
  }

  .chandelier {
    height: 5rem;
    top: 75%;
    left: 5%;
  }

  .chandelier2 {
    height: 5rem;
    top: 75%;
    right: 5%;
  }

  .lightning1 {
    height: 12rem;
    top: 0;
    right: 50%;
  }

  .lightning2 {
    height: 6rem;
    top: 0;
    right: 30%;
  }

  .castle {
    height: 6rem;
    top: 10%;
    right: 5%;
  }

  .death {
    height: 6rem;
    top: 20%;
    left: 6%;
  }

  .death2 {
    height: 15rem;
    bottom: -10%;
    right: -20%;
  }

  .goofy {
    height: 5rem;
    bottom: 0;
    left: 0;
  }
}
