.Header {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Background {
  width: 100%;
  height: calc(100% - 4rem);
  // background-color: #009999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Welcome {
  max-width: min(35vw, 50vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Logo {
  max-height: 50vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Logo img {
  max-height: 20rem;
  max-width: 100%;
  object-fit: contain;
}

.Subtitle {
  font-family: "W95FA", "Arial";
  font-size: 1.4rem;
}

.Taskbar {
  height: 4rem;
  width: 100%;
  // background-color: aqua;
  display: flex;
  align-items: center;
  background-color: #c0c0c0;
  justify-content: space-between;
  border-color: #ecf6f5 #ecf6f500 #ecf6f500 #00000000;
  border-style: solid;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-width: 0.2rem;
}

.StartAndWelcome {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.WelcomeTab {
  width: 15rem;
  height: 2.2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  font-family: "W95FA", "Arial";
  font-size: 1.6rem;
  background-color: #d7d8da;
  border-color: #000000 #ecf6f5 #ecf6f5 #000000;
  border-style: solid;
  border-width: 0.2rem;
}

.welcomeTab {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-color: #bdbdbd00 #bdbdbd #bdbdbd #bdbdbd00;
  border-style: solid;
  border-width: 0.15rem;
}

.welcomeTab img {
  max-height: 80%;
  margin-right: 0.4rem;
  margin-left: 0.3rem;
}

.Time {
  display: flex;
  width: 7rem;
  height: 2rem;
  margin-right: 0.5rem;
  background-color: #c0c0c0;
  border-color: #000000 #ffffff #ffffff #000000;
  border-style: solid;
  border-width: 0.1rem;
  justify-content: center;
  align-items: center;
}

.Time p {
  font-size: 1.2rem;
  font-family: "W95FA", "Arial";
}

.TaskbarOverlay {
  height: 4rem;
  width: 100%;
  position: absolute;
  left: 0
}

.TaskbarOverlay img {
  height: 70%;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .Welcome {
    max-width: min(70vw, 25rem);
  }
  .StartAndWelcome {
    width: 100%;
  }
  .WelcomeTab {
    width: min(70%, 15rem);
  }
  .Time {
    width: 0;
    border: none;
    margin: 0;
  }
}
