.Nav {
    position: fixed;
    top: 30vh;
    z-index: 2;
}

@media only screen and (max-width: 1000px) {
    .Nav {
        top: 10vh;
    }
  }