.DrawingPreview {
  max-width: min(90%, 24rem);
  min-width: 18rem;
}

.DrawingPreview:hover {
  cursor: pointer;
}

.DrawingPreview img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}