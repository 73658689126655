.Button {
  width: fit-content;
  padding-right: 0.15rem;
  padding-bottom: 0.15rem;
  background-color: rgb(0, 0, 0);
  margin-block: 0.5rem;
}

.Button:hover {
  cursor: pointer;
}

.button {
  height: 1.7rem;
  width: fit-content;
  padding-inline: 0.4rem;
  padding-block: 0.2rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-family: "W95FA", "Arial";
  background-color: #ced0cf;
  border-color: #ffffff #838383 #838383 #ffffff;
  border-style: solid;
  border-width: 0.2rem;
}

.button:active {
  border-color: #838383 #ffffff #ffffff #838383;
}

.button img {
  max-height: 90%;
  margin-right: 0.4rem;
  margin-left: 0.1rem;
}
