.MinimizableWindow {
  font-family: "W95FA", "Arial";
}

.WindowMinimized {
  height: 6rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c0c0c0;
  border-color: #ffffff #838383 #838383 #ffffff;
  border-style: solid;
  border-width: 0.2rem;
  padding: 0.25rem;
}

.WindowMinimized:hover {
  cursor: pointer;
}

.windowMinimized {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000066;
}

.MinimizedTitle {
  color: white;
  font-size: 1.3rem;
  writing-mode: vertical-lr;
  text-orientation: upright;
}
