.DrawingPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: url("https://web.archive.org/web/20091025185233if_/http://www.geocities.com/skyewallpapers/bgskulls.gif");
  // background-repeat: repeat;
}

.drawingPage {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.WindowContent {
  max-height: 80vh;
}

.WindowContent img {
  max-height: 60vh;
  max-width: 100%;
  object-fit: contain;
}

.text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "W95FA", "Arial";
}
