.Window {
  width: fit-content;
  font-family: "W95FA", "Arial";
  // padding-right: 0.15rem;
  // padding-bottom: 0.15rem;
  border-color: #00000000 #000000 #000000 #00000000;
  border-style: solid;
  border-width: 0.15rem;
  // background-color: rgb(0, 0, 0);
}

.window {
  // background-color: #c0c0c0;
  border-color: #ffffff #838383 #838383 #ffffff;
  border-style: solid;
  border-width: 0.2rem;
  // padding: 0.3rem;
}

.win {
  border-color: #c0c0c0;
  border-style: solid;
  border-width: 0.3rem;
}

.WindowHeader {
  height: 3.8rem;
  width: 100%;
  background-color: #c0c0c0;
}

.windowHeader {
  height: 2.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000066;
}

.Title {
  color: white;
  margin-left: 1rem;
  font-size: 1.3rem;
}

.Close {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c0c0c0;
  border-color: #ffffff #838383 #838383 #ffffff;
  border-style: solid;
  border-width: 0.15rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.Close:hover {
  cursor: pointer;
}

.Close:active {
  border-color: #838383 #ffffff #ffffff #838383;
}

.Content {
  border-color: #808080;
  border-style: solid;
  border-width: 0.2rem;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 0.15rem;
}

.contentTransparent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-color: #000000;
  border-style: solid;
  border-width: 0.15rem;
}

.WindowHidden {
  width: fit-content;
  font-family: "W95FA", "Arial";
  padding-right: 0.15rem;
  padding-bottom: 0.15rem;
  background-color: rgba(0, 0, 0, 0);
}

.windowHidden {
  background-color: #c0c0c000;
  border-color: #ffffff00;
  border-style: solid;
  border-width: 0.2rem;
  padding: 0.3rem;
}

.windowHeaderHidden {
  height: 2.5rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00006600;
}

.ContentHidden {
  border-color: #80808000;
  border-style: solid;
  border-width: 0.2rem;
}

.contentHidden {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0);
  border-color: #00000000;
  border-style: solid;
  border-width: 0.15rem;
}
